(function () {
    'use strict';
    /**
     aflGrid Directive
     Intended to be a component, but usage of nested directives
     require compile precedure to pass the argument
     */
    angular.module('aerosApp').directive('aflGrid', aflGrid);

    aflGrid.$inject = ['AFL_GRID', 'Notification', '$state', 'aflGridDeletConfirmModal', 'appDispatcher', 'aflGridFilterFactory'];
    function aflGrid(AFL_GRID, Notification, $state, aflGridDeletConfirmModal, appDispatcher, aflGridFilterFactory) {
        var onReload = _.noop;
        var modelType;

        return {
            restrict: 'E',
            templateUrl: '/static/templates/directive/afl-tool-grid.html',
            compile: function () {
                return {pre: link, post: _.noop};
            },
            scope: {
                options: '=',
                license: '=',
                series: '=',
                columns: '=',
                filters: '=',
                defaultFilters: '=',
                modelType: '@',
                onReload: '&'
            }
        };

        function link(scope) {
            var filterFactory = aflGridFilterFactory($state.params.toolType, scope.modelType);

            scope.filtersSelected = angular.copy(scope.defaultFilters);

            //console.log("license=" + scope.license);

            var agGridOptions = setGridOptions(scope.series, scope.filtersSelected);

            onReload = scope.onReload;
            modelType = scope.modelType;


            scope.$watchCollection('series', function (series) {
                setGridOptions(series, scope.filtersSelected);
                agGridOptions.api.setRowData(scope.gridOptions.rowData);
            });

            scope.$watchCollection('filtersSelected', function (filters) {
                setGridOptions(scope.series, filters);
                agGridOptions.api.onFilterChanged();
            });

            scope.$on(scope.modelType + 'CreateSuccess', handleSuccess('Create'));
            scope.$on(scope.modelType + 'UpdateSuccess', handleSuccess('Update'));
            scope.$on(scope.modelType + 'DeleteSuccess', handleSuccess('Delete'));

            scope.$on(scope.modelType + 'CreateError', handleError);
            scope.$on(scope.modelType + 'UpdateError', handleError);
            scope.$on(scope.modelType + 'DeleteError', handleError);

            function setGridOptions(series, filters) {
                return scope.gridOptions =
                    compileOptions(series, filters);
            }

            // Component's UI logic
            function compileOptions(series, filters) {
                return _.defaults(scope.options, {
                    modelType: scope.modelType,
                    columnDefs: scope.columns,
                    enableSorting: true,
                    enableFilter: true,
                    rowSelection: 'multiple',
                    enableColResize: true,
                    angularCompileRows: true,
                    rowHeight: 44,
                    rowData: _.map(series, createModel),
                    doesExternalFilterPass: filterFactory.doesItPass(filters),
                    isExternalFilterPresent: function () {
                        return true;
                    },
                    onRowClicked: function ($event) {
                    },
                    onColumnResized: function($event) {
                        // NOTE: It is unclear to me
                        // why resizing the column works
                        // in aflToolPresetGrid.js without this handler.
                        // But resizing the column in aflToolGrid wipes out preset values
                        // and they need to be reset in this handler.
                        // If we see that this solution makes resizing the columns sluggish, we may
                        // reset the values only at the end of resizing by adding check for $event.finished
                        // but in this case, the there are no preset values while columns are resized

                        if (modelType == 'toolLibrary') {
                            agGridOptions.api.setRowData(scope.gridOptions.rowData);
                        }
                    }
                });
            }
        }

        function createModel(data) {
            return _.extend(Object.create({
                can: getUserAbility(data),
                delete: _.partial(deleteModel, data),
                hide: _.partial(hideModel, data),
                edit: _.partial(openToolModal, 'edit', data),
                info: _.partial(openToolModal, 'view', data),
                copy: _.partial(copyToolModal, data)
            }), data);
        }

        function copyToolModal(model) {
            openToolModal('add', _.defaults({name: model.name + ' (copy)'}, model));
        }

        function openToolModal(modeName, model) {
            appDispatcher.broadcast(modeName + cap(modelType), model, modeName);
        }

        function getUserAbility(model) {
            return {
                edit: !model.generic && !model.readOnly && _.isFunction(model.update),
                delete: !model.generic && !model.readOnly && _.isFunction(model.destroy),
                hide: !model.readOnly && _.isFunction(model.deactivate),
                view: !model.denyView,
                copy: !model.denyCopy
            }
        }

        function hideModel(model) {
            if (model.active == false) {
                model.deactivate().then(onReload).then(Notification.success.bind(Notification, 'Successfully enabled')).catch(Notification.error.bind(Notification));
            } else {
                model.deactivate().then(onReload).then(Notification.success.bind(Notification, 'Successfully disabled')).catch(Notification.error.bind(Notification));
            }
        }

        function deleteModel(model) {
            aflGridDeletConfirmModal.open(model).then(_.partial(destroy, model)).then(onReload);
        }

        function destroy(model) {
            model.destroy().then(onReload).then(Notification.success.bind(Notification, 'Successfully deleted')).catch(Notification.error.bind(Notification));
        }

        function handleError(ev, err) {
            var msg = err.data ?
                err.status === 400 ? err.data.detail :
                    err.data.message : 'Uncaught Error';

            Notification.error(msg);
            console.error(err);
        }

        function handleSuccess(mode) {
            return function () {
                Notification.success("Successfully " + cap(mode) + "d");
                onReload();
            };
        }

        // capitalize
        function cap(str) {
            return str.replace(/\b./g, function (a) {
                return a.toUpperCase();
            });
        }
    }
}());
